import { z } from 'zod';
import { notificationDetailsSchema } from './notifications';
export const eventFrameAddedPayloadSchema = z.object({
    event: z.literal("frame_added"),
    notificationDetails: notificationDetailsSchema.optional(),
});
export const eventFrameRemovedPayloadSchema = z.object({
    event: z.literal("frame_removed"),
});
export const eventNotificationsEnabledPayloadSchema = z.object({
    event: z.literal("notifications_enabled"),
    notificationDetails: notificationDetailsSchema.required(),
});
export const notificationsDisabledPayloadSchema = z.object({
    event: z.literal("notifications_disabled"),
});
export const eventPayloadSchema = z.discriminatedUnion("event", [
    eventFrameAddedPayloadSchema,
    eventFrameRemovedPayloadSchema,
    eventNotificationsEnabledPayloadSchema,
    notificationsDisabledPayloadSchema,
]);
